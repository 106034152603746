import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ImageMagick, initializeImageMagick, MagickFormat } from '@imagemagick/magick-wasm';
import styled from "styled-components";
import {blue, orange, red, white} from "./colors";

const Title = styled.h1`
    font-size: 8vw;
    font-weight: 1000;
    line-height: 8vw;
    font-style: italic;
    color: ${red};
`

const Stripes = styled.div`
    background: linear-gradient(
            45deg,
            #FF7F3E 0%,
            #FF7F3E 25%,
            #EF5A6F 25%,
            #EF5A6F 50%,
            #80C4E9 50%,
            #80C4E9 75%,
            #604CC3 75%,
            #604CC3 100%
    );
    
    position: absolute;
    
    top: 0;
    left: 0;
    right: 0;
    
    height: 10px; /* Set desired height */
    width: 100%; /* Set desired width */
`

const Idx = () => {
    const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };

    const handleClick = async () => {
        const images = await Promise.all(files.map(file => file.arrayBuffer()));
        const byteArrays = images.map(buffer => new Uint8Array(buffer));
        await test(byteArrays);  // Ensure we wait for the test function to complete
    };

    return (
        <React.StrictMode>
            <div className={"w-full min-h-screen"}  style={{backgroundColor: white}}>
                <Stripes></Stripes>
            <div className="md:container md:mx-auto pt-10">
                <Title>RETINA<br />CONVERTER</Title>
                <h1 className={"font-black"} style={{color: red, fontSize: "3vw"}}>HEIC-2-jpg</h1>
                <div className="mb-3 w-full flex-row mt-2">
                        <input
                            type="file"
                            accept={".heic"}
                            className=""
                            id="formFileMultiple"
                            multiple
                            onChange={handleFileChange}
                        />
                        <button className={"p-2 text-white rounded border mt-2 text-xl font-black hover:bg-red-50"} style={{backgroundColor: red, color: white}}
                                onClick={handleClick}>Process
                        </button>
                </div>
            </div>
            </div>

        </React.StrictMode>
);
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<Idx />);

async function test(images: Uint8Array[]) {
    const wasmLocation = new URL('@imagemagick/magick-wasm/magick.wasm', import.meta.url).href;

    const wasmResponse = await fetch(wasmLocation);
    const wasmBytes = await wasmResponse.arrayBuffer();

    await initializeImageMagick(wasmBytes);

    // Use a loop to handle asynchronous read and write operations
    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        await new Promise<void>((resolve) => {
            ImageMagick.read(image, (magickImage) => {
                magickImage.write(MagickFormat.Jpeg, (jpegData) => {
                    const blob = new Blob([jpegData], { type: 'image/jpeg' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `image_${i + 1}.jpeg`;
                    a.click();
                    URL.revokeObjectURL(url);
                    console.log(`Processed image ${i + 1}`);
                    resolve();  // Resolve the promise once the image is processed
                });
            });
        });
    }
}
